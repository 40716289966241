//@import url("src/assets/fonts/Arial.ttf");
//@import url("src/assets/fonts/Helvetica.ttf");
//@import url("src/assets/fonts/Q Serif.ttf");
//@import url("src/assets/fonts/Sans.ttf");
@font-face {
  font-family: 'Arial';
  src: url('/assets/fonts/Arial.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica.ttf';
  src: url('/assets/fonts/Helvetica.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Q Serif.ttf';
  src: url('/assets/fonts/Q Serif.ttf');
  font-display: swap;
}
